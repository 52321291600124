<template>
     <div id="app">
          <transition name="toggleMain">
               <PreviewModal />
          </transition>
          <HeaderPart />
          <QuickBar v-if="screenWidth >= screenBreakXL || navOpen" />
          <main role="main">
               <BreadCrumbs />
               <transition name="toggleMain">
                    <router-view :key="$route.fullPath" />
               </transition>
          </main>
          <FooterPart />
     </div>
</template>

<script>
import { mapState } from "vuex"
export default {
     name: "App",
     computed: {
          ...mapState([
               "cmsUrl",
               "screenWidth",
               "screenBreakXL",
               "activeMenuPoint",
               "navOpen",
               "token",
               "pages",
          ]),
          page: {
               get() {
                    return (
                         this.pages.find(
                              (page) =>
                                   page.attributes.pagename === this.$route.name
                         )?.attributes || {}
                    )
               },
               deep: true,
          },
     },
     data() {
          return {
               rootFont: 18,
               seoData: {},
          }
     },
     components: {
          HeaderPart: () => import("@/components/templateParts/HeaderPart.vue"),
          QuickBar: () => import("@/components/QuickBar.vue"),
          FooterPart: () => import("@/components/templateParts/FooterPart.vue"),
          BreadCrumbs: () =>
               import("@/components/templateParts/BreadCrumbs.vue"),
          PreviewModal: () => import("@/components/PreviewModal.vue"),
     },
     metaInfo() {
          const { pagetitle, og_image, pagekeywords, pagedescription } =
               this.page || {}

          let image = ""
          if (og_image && og_image.data && og_image.data.attributes) {
               image = this.cmsUrl + og_image.data.attributes.url
          }
          const seoAlt = {
               title: "Berchenschule Konstanz",
               description: "Webseite der Berchenschule Konstanz",
               keywords:
                    "Berchenschule, Konstanz, Werkrealschule, Grundschule, Schule",
               image: "https://cms.berchenschule-konstanz.de/uploads/BSK_Logo_Building_80b25ce070.svg",
               locale: "de",
          }
          return {
               title: pagetitle || seoAlt.title,
               meta: [
                    {
                         name: "description",
                         content: pagedescription || seoAlt.description,
                    },
                    { name: "robots", content: "index, follow" },
                    {
                         name: "keywords",
                         content: pagekeywords || seoAlt.keywords,
                    },
                    {
                         property: "og:title",
                         content: pagetitle || seoAlt.title,
                    },
                    {
                         property: "og:description",
                         content: pagedescription || seoAlt.description,
                    },
                    { property: "og:image", content: image || seoAlt.image },
                    {
                         property: "og:locale",
                         content: seoAlt.locale,
                    },
               ],
          }
     },
     methods: {
          handleResize() {
               this.$store.dispatch("setScreenWidth")
          },
          setRootFontSize() {
               document.documentElement.style.fontSize = this.rootFont + "px"
          },
          changeRootFont(op) {
               this.rootFont = this.rootFont + op
               this.setRootFontSize()
          },
     },
     created() {
          this.$store.dispatch("init_DataPull")
     },
     mounted() {
          // Set ScreenWidth
          window.addEventListener("resize", this.handleResize)
          this.handleResize()
          // Setze Schriftgröße von
          this.setRootFontSize()
     },
     beforeDestroy() {
          window.removeEventListener("resize", this.handleResize)
     },
}
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'
</style>
