import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"

import Meta from "vue-meta"
Vue.use(Meta)

// VUE FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
library.add(fas, fab)
Vue.component("font-awesome-icon", FontAwesomeIcon)

Vue.config.productionTip = false

new Vue({
     router,
     store,
     render: (h) => h(App),
}).$mount("#app")
